import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import App from './App.svelte';

new App({ target: document.body });

Sentry.init({
  // eslint-disable-next-line no-undef
  environment: process.env.NODE_ENV,
  dsn: 'https://090e7c1eab0d45f9b42a15a7797c0241@o1138045.ingest.sentry.io/6324006',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
