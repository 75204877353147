// eslint-disable-next-line no-undef
const host = `${process.env.API}`;
const headers = new Headers();

const contractCache = {};

headers.append('Content-Type', 'application/json');

export const ContractService = () => ({  
  loadContract: async (contractId, token) => {
    if (contractCache[contractId]) {
      return Promise.resolve(contractCache[contractId]);
    }

    return fetch(`${host}/v1/credit-contract/${contractId}?acctk=${token}`, {
      headers: headers,
    }).then(response => {
      if (response.status === 200) {
        contractCache[contractId] = response.json();
        return Promise.resolve(contractCache[contractId]);
      }
    })
  },
  savePayment: async (contractId, payment, creditCard) =>
    fetch(`${host}/v1/credit-contract/${contractId}/payment`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        ...payment,
        creditCard
      }),
    }).then(response => response.json()
  ),
  getRealEstateName: (realEstateId) => 
    fetch(`${host}/v1/real-estate/${realEstateId}/name`).then(response => response.json()),
  addCVV: (contractId, cvv, tk) => 
    fetch(`${host}/v1/credit-contract/${contractId}/cvv?acctk=${tk}`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({
        cvv: cvv
      }),
    }),
});
