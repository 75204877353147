
const cleanString = str => str.replace(/\W*/gi, '');

const cleanCurrency = num => num.replace(/(?!,)\D*/gi, '');

const transformToNumber = value => Number(value.replace(',', '.'));

const currencyMask = value => value.toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

export const Utils = {
  formatDate: (date) =>
    new Intl.DateTimeFormat('pt-BR')
      .format(new Date(+date)),
  formatDateTime: date => Intl.DateTimeFormat('pt-BR', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  }).format(+date),
  revertDateToTimeStamp: (date) =>
    +new Date(date.split('/').reverse().join('/')),
  deepClone: obj => JSON.parse(JSON.stringify(obj)),
  BlobToBase64: (file, options) => {
    const _file = {
      name: file.name,
      size: file.size,
      path: file.path,
    };

    if (Array.isArray(file)) {
      throw new TypeError('File should be a object');
    }

    const { type } = options;

    const blob = new Blob([file], type);
    const reader = new FileReader();
    const promise = new Promise((resolve) => {
      reader.addEventListener('load', (e) => {
        const base64String = e.currentTarget.result;
        _file.base64 = base64String.substr(base64String.indexOf(',') + 1);
        resolve(_file);
      });
    });

    reader.readAsDataURL(blob);

    return promise;
  },
  apllyMaskFields: (value, mask, evt) => {
    if (!value) return evt.target.value = value;

    const splittedValue = cleanString(value).split('');

    if (mask === 'currency') {
      const plainValue = cleanCurrency(value);
      const currencyValue = transformToNumber(plainValue);
      return evt.target.value = currencyMask(currencyValue);
    }

    const splittedMask = mask.split('');
    splittedMask
      // eslint-disable-next-line no-sequences
      .reduce((acc, char, i) => (/\W/.test(char) && acc.push(i), acc), [])
      .forEach(position =>
        splittedValue.splice(position, 0, splittedMask[position]));


    evt.target.value = splittedValue.join('');
    return evt.target.value;
  },
  resetMaskFields: (value, mask, evt) => {

    if (!value) return;

    if (mask === 'currency' && value) {
      const val = String(cleanCurrency(value)).replace('.', ',');

      evt && (evt.target.value = val);
      return val;
    }

    const resetedValue = cleanString(value);
    evt && (evt.target.value = resetedValue);
  },
  formatBRLValue: (value) =>
    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  formatBRLValueToNumber: (value) => {
    const monetary = Number(value.replace(',', '.'));
    return Number(monetary.toFixed(2));
  },
  applyMask: mask => value => {
    const splittedValue = value.split('');

    const splittedMask = mask.split('');
    splittedMask
      // eslint-disable-next-line no-sequences
      .reduce((acc, char, i) => (/\W/.test(char) && acc.push(i), acc), [])
      .forEach(position =>
        splittedValue.splice(position, 0, splittedMask[position]));

    return splittedValue.join('');
  },
};
