<script>
  import Dialog, { Content, Actions, InitialFocus } from '@smui/dialog';

  import { Icon } from '@smui/icon-button';
  import Button, { Label } from '@smui/button';

  export let icon;
  export let open;
  export let onCancel;
  export let onConfirm;
  export let type = 'warning';
  export let cancelLabel = 'Cancelar';
  export let confirmLabel = 'Confirmar';
  export let size = 'width: 34rem; max-width: calc(100vw - 2rem); height: 13rem;';
</script>

<Dialog
  bind:open
  aria-labelledby="large-scroll-title"
  aria-describedby="large-scroll-content"
  surface$style={size}
  on:MDCDialog:closed={onCancel}
>
  <Content id="large-scroll-content" class="confirm-action-centered-content">
    <Icon class={`material-icons ${type}`}>{icon}</Icon>
    <div class="mdc-layout-grid__cell--span-12">
      <slot></slot>
    </div>
  </Content>

  <Actions>
    <Button color="secondary" on:click={onCancel}>
      <Label>{cancelLabel}</Label>
    </Button>

    <Button defaultAction color="primary" on:click={onConfirm} use={[InitialFocus]}>
      <Label>{confirmLabel}</Label>
    </Button>
  </Actions>
</Dialog>

<style>
  :global(.confirm-action-centered-content) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  :global(.material-icons.confirm) {
    font-size: 2rem;
    color: #4caf50;
  }

  :global(.material-icons.warning) {
    font-size: 2rem;
    color: #FFC107;
  }

</style>
