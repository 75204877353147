// eslint-disable-next-line no-undef
const host = `${process.env.API}`;
const headers = new Headers();

const contractCache = {};

headers.append('Content-Type', 'application/json');

export const CreditAnalysisService = () => ({
  loadCreditAnalysis: async (token) => {
    if (contractCache[token]) {
      return Promise.resolve(contractCache[token]);
    }

    return fetch(`${host}/v1/credit-analysis/${encodeURIComponent(token)}`, {
      headers: headers,
    }).then(response => {
      if (response.status === 200) {
        contractCache[token] = response.json();
        return Promise.resolve(contractCache[token]);
      }

      if (response.status === 404) {
        return Promise.reject(response);
      }
    })
  },
  chargeCCVerification: async (creditAnalysisId, tk, payload) => {
    headers.set('acctk', tk);

    return fetch(`${host}/v1/credit-analysis/${creditAnalysisId}/attach-cards`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
  },
  savePayment: async (creditAnalysisId, payment, creditCard) =>
    fetch(`${host}/v1/credit-analysis/${creditAnalysisId}/payment`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        ...payment,
        creditCard
      }),
    }).then(response => response.json()
  ),
  getRealEstateName: (realEstateId) =>
    fetch(`${host}/v1/real-estate/${realEstateId}/name`).then(response => response.json()),
  addCVV: (creditAnalysisId, cc, tk) =>
    fetch(`${host}/v1/credit-analysis/${creditAnalysisId}/cvv/${encodeURIComponent(tk)}`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({
        ...cc
      }),
    }),
  updateProposalStatus: (realEstateId, creditAnalysisId, status, tk) => {
    headers.append('acctk', tk);
    return fetch(`${host}/v1/real-estate/${realEstateId}/credit-analysis/${creditAnalysisId}/status`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        status,
      }),
    })
  },
  getProposal: (partnerId, proposalId, tk) => {
    headers.append('x-acctk', tk);
    return fetch(`${host}/v1/partner/${partnerId}/proposal/${proposalId}/external`, {
      headers,
    }).then(res => res.json());
  }
});
