<script>
  import Button from '@smui/button';

  export let back = false;
  export let dismiss = false;
</script>

<div class="transition-screen mdc-layout-grid__inner">
  <slot name="title" />
  <slot name="subtitle" />
  <slot name="content" />

  <div class="mdc-layout-grid__inner mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet" class:space-between={back}>
    {#if back}
      <Button type="button" color="secondary" variant="raised" on:click={back}>
        Voltar
      </Button>
    {/if}

    {#if !!dismiss}
      <div class="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-2-phone"></div>
      <div class="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-2-phone">
        <Button type="button" color="secondary" variant="raised" on:click={dismiss}>
          Continuar
        </Button>
      </div>
    {/if}
  </div>
</div>

<style>
.transition-screen {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 100;
  background-color: #FDFDFD;
  backdrop-filter: blur(5px);
  bottom: 0;
  height: 100%;
  padding-top: 3rem;
  padding-left: .5rem;
  padding-right: 0.5rem;
  width: 96vw;
  max-width: 100%;
}

:global(.transition-screen h1) {
  color: rgba(0,0,0, 1);
  text-shadow: #FFF;
  font-size: 2.236rem;
  margin: 0;
  text-transform: uppercase;
  width: 100%
}

:global(.transition-screen h2) {
  color: rgba(0,0,0, .8);
  font-size: 1.618rem;
  margin: 1.618rem 0;
  width: 100%
}

:global(.transition-screen p) {
  color: rgba(0,0,0, .8);
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 1.618rem 0;
  text-align: justify;
}

@media (min-width: 576px) {
  .transition-screen {
    width: 100vw;
  }

  :global(.transition-screen p) {
    max-width: 80%;
    font-size: 1rem;
    line-height: 1.618rem;
    margin: 0.8rem auto;
    text-align: justify;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {

  .transition-screen {
    width: 99vw;
  }

  :global(.transition-screen h1) {
    font-size: 2.236rem;
    max-width: 98%;
  }

  :global(.transition-screen h2) {
    font-size: 1.618rem;
  }

  :global(.transition-screen p) {
    font-size: 1rem;
    line-height: 1.6rem;
    margin: 1rem auto;
    max-width: 96%;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .transition-screen {
    padding-top: 4rem;
    width: 100vw;
  }
}

@media (min-width: 1200px) {
  .transition-screen {
    padding-top: 8rem;
    width: 100vw;
  }
}

@media (min-width: 1400px) {
  .transition-screen {
    padding-top: 8rem;
    width: 100vw;
  }
}

</style>
