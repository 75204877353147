<script>
  import './detail.scss';

  import Button, { Label } from '@smui/button';
  import LinearProgress from '@smui/linear-progress';
  import IconButton from '@smui/icon-button';
  import Snackbar, { Actions } from '@smui/snackbar';

  import CreditCardForm from '../../../components/credit-card-form/credit-card-form.svelte';
  import GuaranteeDetail from '../../../components/guarantee-detail/guarantee-detail.svelte';
  import RentDetail from '../../../components/rent-detail/rent-detail.svelte';
  import SummaryCard from '../../../components/summary-card/summary-card.svelte';

  export let creditCards;
  export let correctData;
  export let snackMessage;
  export let registerFocus;
  export let creditAnalysis;

  export let realEstateName;

  export let loading;
  export let notFound;

  export let iuguService;
  export let obfuscateNumber;
  export let onValidateCreditCard;
  export let excludeCreditCard;

  export let onSendCreditCards;

  let snackbarWithClose;

  $: if ($snackMessage) {
    snackbarWithClose.open();
  }
</script>

<main class="mdc-layout-grid__inner proposal-detail">

  {#if $loading}
    <div class="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <LinearProgress indeterminate />
    </div>
  {/if}

  {#if $notFound}
    <div class="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      Proposta não encontrada. Verifique seu link de acesso ou fale com o nosso parceiro, para mais informações.
    </div>
  {/if}

  {#if $creditAnalysis._id}
    {#if $correctData}
      <section class="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
        <CreditCardForm
          {onValidateCreditCard}
          {registerFocus}
          {iuguService}
          {obfuscateNumber}
          {onSendCreditCards}
          {creditCards}
          {excludeCreditCard}
          />
      </section>
    {:else}
      <aside class="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone" >
        <SummaryCard contract={creditAnalysis} {realEstateName} />
      </aside>

      <section class="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-5-tablet mdc-layout-grid__cell--span-4-phone">
        <GuaranteeDetail guarantee={$creditAnalysis.plan} />
        <RentDetail realtyData={$creditAnalysis.realtyData} />

        <Button
          class="position-right"
          variant="raised"
          color="primary"
          on:click={() => $correctData = true}>
          Confirmar dados
        </Button>
      </section>
    {/if}

  {/if}
</main>

<Snackbar
  bind:this={snackbarWithClose}
  on:SMUISnackbar:closed={() => {
    $snackMessage = '';
    snackbarWithClose.close();
  }}
>
  <Label>{$snackMessage}</Label>
  <Actions>
    <IconButton class="material-icons" title="Fechar">close</IconButton>
  </Actions>
</Snackbar>
