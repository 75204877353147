<script>
  import { writable } from 'svelte/store';

  import { createForm } from 'felte';

  import { validator } from '@felte/validator-yup';
  import * as yup from 'yup';

  import Button, {Icon} from '@smui/button';
  import Card from '@smui/card';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import HelperText from '@smui/textfield/helper-text';
  import IconButton from '@smui/icon-button';
  import LinearProgress from '@smui/linear-progress';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';

  import CreditCardBrand from '../creditcard-brand/ccbrand.svelte';
  import UploadButton from '../upload-button/upload-button.svelte';

  import {Utils} from '../../services/utils.js';

  import {desktopMode, tabletMode} from '../../store/stores';

  export let creditCards;
  export let registerFocus;
  export let obfuscateNumber;
  export let onSendCreditCards;
  export let onValidateCreditCard;
  export let iuguService = {};
  export let excludeCreditCard;

  const resetFile = writable(false);
  const loading = writable(false);

  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const years = [];
  for (let i = 0; i < 10; i++) {
    years.push(`${(new Date).getFullYear() + i}`);
  }

  const REQUIRED_FIELD = 'Campo requerido';
  const NO_SPACES = 'Remover espaço em branco no final ou começo do campo';
  const INVALID_FORMAT_PDF = 'Formato inválido. Apenas PDF é aceito';
  const INVALID_DATE = 'Data Informada inválida';

  const initialValues = {
    bill: {
      base64: '',
      name: '',
      path: '',
      size: 0,
    },
    brand: '',
    cvv: '',
    defaultPaymentMethod: false,
    month: '01',
    name: '',
    number: '',
    year: (new Date().getFullYear().toString()),
  };

  const schema = yup.object({
    name: yup.string()
      .trim(NO_SPACES)
      .required(REQUIRED_FIELD)
      .transform((value, _, context) =>
        context.isType(value) && value !== null ?
          value.trim() : value),
    number: yup.string().test('test-number',
      'Número de cartão inválido',
      value => iuguService.validateCreditCard(value))
    .required(REQUIRED_FIELD),
    cvv: yup.string().test('test-number',
      'CVV inválido',
      (value, ctx) => iuguService.validateCVV(value, ctx.parent.brand))
    .required(REQUIRED_FIELD),
    month: yup.string().required(REQUIRED_FIELD),
    year: yup.string().required(REQUIRED_FIELD),
    bill: yup.object({
      base64: yup.string().required(REQUIRED_FIELD),
      name: yup.string().required(REQUIRED_FIELD).test('pdf-file', INVALID_FORMAT_PDF, (val) => /\.pdf/gi.test(val)),
      path: yup.string().optional(),
      size: yup.number().optional(),
    }).required(REQUIRED_FIELD),
    brand: yup.string().optional(),
    defaultPaymentMethod: yup.boolean().optional(),
    invalidDate: yup.boolean().optional(),
  });

  const getCCBrand = num => iuguService.getBrandByCreditCardNumber(num);
  const shortYear = yr => yr.toString().substr(2, 2);
  const shortNumber = num => !$desktopMode ? num.substr(num.length - 4, 4) : num;

  const addCreditCard = async cc => {
    $loading = true;
    const {bill, invalidDate, ...rest} = cc;
    const result = await onValidateCreditCard(rest, bill);
    cc.valid = result.valid;
    cc.message = result.message;
    cc.creditCardId = result.creditCardId;
    cc.iugudata = result.iugudata;
    cc.defaultPaymentMethod = !!cc.defaultPaymentMethod;
    $creditCards = [...$creditCards, cc];
    $loading = false;

    setTimeout(() => {
      document.getElementById('send-credit-cards').scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      });
    }, 300)

    return cc;
  };

  const removeCreditCard = async i => {
    const creditCard = $creditCards[i];

    if (creditCard.status === 'VALIDATION') {
      await excludeCreditCard($creditCards[i]._id);
    }

    $creditCards = $creditCards.filter(
      (cc, index) => index !== i);
  }

  const {
    data: ccForm,
    errors,
    form,
    isValid,
    reset,
    setData,
  } = createForm({
    extend: validator({schema}),
    initialValues,
    onSubmit: values => {
      $resetFile = true;
      reset();
      return addCreditCard(values);
    },
    transform: data => {
      data.brand = data.number && getCCBrand(data.number);
      return data;
    },
    validate: values => {
      const errors = {};
      const validationRes = !iuguService.validateExpiration(`${values.month}/${values.year}`);

      if (validationRes) {
        errors.invalidDate = INVALID_DATE;
      }
      return errors;
    }
  });

  const getBillCardInfo = (file, base64) => setData('bill', {
    base64,
    name: file.name,
    path: file.path,
    size: file.size,
    type: 'CARD_BILL',
  });
</script>

<div class="mdc-layout-grid__inner margin-bottom">
  {#if $loading}
    <div class="mdc-layout-grid__cell--span-12">
      <LinearProgress indeterminate />
    </div>
  {/if}

  <h1 class="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    Cartão de Crédito
  </h1>

  <form class="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone" use:form>
    <Card padded class="mdc-layout-grid__inner margin-bottom">
      <div class="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-3-phone">
        <Textfield bind:value={$ccForm.number}
          useActions={registerFocus}
          label="Número do Cartão"
          class="full-width"
          input$name="number"
          input$id="number"
          input$autocomplete="creditcard"
          input$emptyValueUndefined
          input$data-felte-keep-on-remove
          invalid={!!($errors?.number?.length)}
          on:focus={(e) => Utils.resetMaskFields($ccForm.number, null, e)}
          on:blur={(e) =>
            $ccForm.number = Utils.apllyMaskFields($ccForm.number, '0000 0000 0000 0000', e)}
          required>
          <HelperText validationMsg slot="helper">
            {$errors?.number}
          </HelperText>
        </Textfield>
      </div>

      <div class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-1-phone">
        <CreditCardBrand brand={$ccForm.brand} />
      </div>

      <div class="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone">
        <Textfield bind:value={$ccForm.name}
          label="Nome impresso no Cartão"
          class="full-width"
          input$name="name"
          input$id="name"
          input$data-felte-keep-on-remove
          input$emptyValueUndefined
          invalid={!!($errors?.name?.length)}
          required>

            <HelperText validationMsg slot="helper">
              {$errors?.name}
            </HelperText>

        </Textfield>
      </div>

      <div class="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-2-phone">
        <Select label="Mês" bind:value={$ccForm.month} required style="max-width: 5.6rem">
          {#each months as month}
            <Option value={month}>{month}</Option>
          {/each}
        </Select>
      </div>

      <div class="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-2-phone">
        <Select label="Ano" bind:value={$ccForm.year} required style={$desktopMode ? 'max-width: 9.6rem' : 'max-width: 6.6rem'}>
          {#each years as year}
            <Option value={year}>{year}</Option>
          {/each}
        </Select>
      </div>

      <div class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-2-phone">
        <Textfield
          label="CVV"
          class="full-width"
          input$name="cvv"
          input$id="cvv"
          input$emptyValueUndefined
          input$data-felte-keep-on-remove
          invalid={!!($errors?.cvv?.length)}
          required>
          <HelperText validationMsg slot="helper">
            {$errors?.cvv}
          </HelperText>
        </Textfield>
      </div>

      {#if !$desktopMode && !$tabletMode}
        <div class="mdc-layout-grid__cell--span-2-phone"></div>
      {/if}

      <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone position-left">
        <UploadButton getInfoUploadFile={(file, b64) => getBillCardInfo(file, b64)}
          labelButton="Anexar Fatura (.pdf) *"
          {resetFile}
          />

          {#if $ccForm.bill?.name && !RegExp('.pdf$', 'gi').test($ccForm.bill?.name)}
            <p style="color: #900">Formato escolhido inválido. O documento deve ser do tipo PDF.</p>
          {/if}
      </div>

      <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone position-left">
        <FormField>
          <Checkbox bind:checked={$ccForm.defaultPaymentMethod} />
          <span slot="label">Usar esse cartão também para pagamento</span>
        </FormField>
      </div>

      <div class="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
        <Button
          color="secondary"
          variant="raised"
          type="submit"
          disabled={!$isValid}>
            + Adicionar Cartão
        </Button>
      </div>
    </Card>
    {#if $loading}
      <div class="mdc-layout-grid__cell--span-12">
        <LinearProgress indeterminate />
      </div>
    {/if}
  </form>

  <div class="mdc-layout-grid__inner mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    {#if $creditCards.length}
      <div
        class="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone margin-bottom"
        id="send-credit-cards"
        >
        <Button
          class="full-width"
          color="primary"
          variant="raised"
          disabled={$creditCards.some(cc => !cc.valid && cc.status !== 'VALIDATION')}
          type="button"
          on:click={() => {
            $loading = true;
            onSendCreditCards($creditCards);
            $loading = false;
          }}>
            Finalizar Validação de Cartões
        </Button>
      </div>

      {#each $creditCards as creditCard, i}
        <Card padded class="mdc-layout-grid__inner mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone margin-bottom card-validation-preview">
          <div class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-1-phone">
            <CreditCardBrand brand={creditCard.brand} />
          </div>
          <div class="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-2-phone">
            {#if $desktopMode}
              <p>{shortNumber(obfuscateNumber(creditCard.number))}</p>
              <p class="text-light">{creditCard.name} ({creditCard.month}/{shortYear(creditCard.year)})</p>
            {:else}
              <p>{creditCard.name}</p>
              <p class="text-light">{shortNumber(obfuscateNumber(creditCard.number))} ({creditCard.month}/{shortYear(creditCard.year)})</p>
            {/if}
          </div>

          <div class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-1-tablet mdc-layout-grid__cell--span-1-phone">
            {#if !creditCard.status || creditCard.status === 'VALIDATION'}
              <IconButton
                class="material-icons"
                aria-label="Conta"
                on:click={() => removeCreditCard(i)}>
                  delete_outline
              </IconButton>
            {/if}
          </div>

          {#if !creditCard.status}
            <p class="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-3-phone text-light text-overflow-ellipsis">
              {creditCard?.bill?.name}
            </p>
          {/if}
          <p
            class:text-success={creditCard.valid}
            class:text-warning={!creditCard.valid && creditCard.message}
            class:text-notify={!creditCard.valid && !creditCard.message && creditCard.status === 'VALIDATION'}
            class="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-3-phone align-itens-center flex">
            {#if creditCard.valid}
              <Icon class="material-icons margin-right" style="margin-top: -.4rem">verified</Icon> Cartão Verificado
            {:else if creditCard.message}
              <Icon class="material-icons margin-right" style="margin-top: -.4rem">warning</Icon> {creditCard.message}
            {:else}
              <Icon class="material-icons margin-right" style="margin-top: -.4rem">verified</Icon> Cartão Pendente de envio
            {/if}
          </p>
        </Card>
      {/each}

    {/if}
  </div>
</div>

<style>
  :global(.card-validation-preview) {
    min-height: 4.8rem;
    max-height: 8.4rem;
    align-items: center;
  }
  @media (max-width: 30rem) {
    :global(.card-validation-preview p) {
      font-size: 0.8rem;
    }
  }
</style>
