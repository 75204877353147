<script>
  import Header from '../header/header.svelte';
  import PageContent from '../page-content/page-content.svelte';

  export let title = '';
</script>

<style>
  .gray-body {
    background-color: #FAFAFA;
  }
</style>

<div class="gray-body">
  <Header />
  <PageContent {title}>
    <slot />
  </PageContent>
</div>
