<script>
  import { writable } from 'svelte/store';

  import { useLocation, useFocus } from 'svelte-navigator';

  import { CreditAnalysisService } from '../../../services/credit-analysis';
  import { ContractService } from '../../../services/contract';
  import { IuguService } from '../../../services/iugu';
  import { WalletService } from '../../../services/wallet.service';

  import ConfirmAction from '../../../components/confirm-action/confirm-action.svelte';

  import InstructionView from '../../../components/instruction-view/instruction-view.svelte';

  import DetailProposal from './detail.svelte';

  const alertMessage = writable(false);
  const ccValidationResults = writable([]);
  const confirmActionState = writable(false);
  const confirmSendCard = writable(false);
  const correctData = writable(false);
  const creditAnalysis = writable({});
  const creditCards = writable([]);
  const firstTutorial = writable(true);
  const isPrincipalPayer = writable(false);
  const IuguCustomerId = writable('');
  const loading = writable(true);
  const notFound = writable(false);
  const realEstateName = writable({});
  const secondTutorial = writable(true);
  const sentCreditCards = writable(false);
  const snackMessage = writable('');
  const signee = writable({});
  const validateCreditCard = writable(false);

  const location = useLocation();
  const registerFocus = useFocus();

  const tk = $location.search.split('=')[1];

  const iuguService = IuguService();
  const contractService = ContractService();
  const creditAnalysisService = CreditAnalysisService();
  const walletService = WalletService();

  const mapLR = {
    '0': true,
    '00': true,
  };

  const mapIuguTransactionMessage = {
    autorizado: true
  };

  const mapIuguStatusValidTransaction = {
    authorized: true,
    captured: true
  };

  const obfuscateNumber = num => num.replace(/\D/gi, '').substr(12, 16).padStart(19, 'XXXX-XXXX-XXXX-');
  const ab2str = buf => String.fromCharCode.apply(null, new Uint8Array(buf));

  const getCypher = async (str, tk, id) => {
    const bfferStr = new TextEncoder('utf-8').encode(str);

    const keyPair = await window.crypto.subtle.generateKey({
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: 'SHA-256'
      },
      true,
      ['encrypt', 'decrypt']
    );

    const resultCipher = await window.crypto.subtle.encrypt({ name: 'RSA-OAEP' },
      keyPair.publicKey,
      bfferStr);

    const key = await window.crypto.subtle.exportKey('jwk', keyPair.privateKey);

    return {
      pk: JSON.stringify(key, false, 0),
      result: ab2str(resultCipher),
      id,
    };
  };

  const createSigneeWallet = async (proposalId, signee) => {
    const { acctk, email, id } = signee;

    const result = await walletService.createCustomer({
      signeeId: id,
      proposalId,
      email,
      acctk,
    });

    if (result.errors) {
      throw new Error(JSON.stringify(result.errors));
    }

    return result;
  };

  const showAdBlockAlert = () => {
    if (iuguService.isBlockedByAdBlock()) {
      $confirmActionState = true;
      $alertMessage = 'Por favor, desabilite o adblock para continuar';
      return true;
    }
  };

  const fetchCreditCards = (signeeId, walletId, acctk) =>
    walletService.getCreditCards({
      signeeId,
      walletId,
      acctk,
    });

  const fetchProposal = async () => {
    try {
      $creditAnalysis = await creditAnalysisService.loadCreditAnalysis(tk);

      $signee = $creditAnalysis
        .signees.find(signee => signee.acctk === decodeURIComponent(tk));

      if (!$signee) {
        throw new Error('Falha ao carregar o signatário da proposta');
      }

      const result = await contractService.getRealEstateName($creditAnalysis.realEstateId);

      $realEstateName[$creditAnalysis.realEstateId] = result.legalName;
      $isPrincipalPayer = $creditAnalysis.signees.find(_signee =>
          $signee.acctk === decodeURIComponent(tk) && _signee.principalPayer);

      $IuguCustomerId = await createSigneeWallet($creditAnalysis._id, $signee);

      const _creditCards = await fetchCreditCards($signee.id, $IuguCustomerId.walletId,
        encodeURIComponent($signee.acctk));

      $creditCards = _creditCards.map(cc => ({
        ...cc,
        year: '' + cc.iugu.year,
        month: ('' + cc.iugu.month).padStart(2, '0'),
        brand: cc.iugu.brand.toLowerCase(),
        valid: cc.status === 'VALID',
        name: cc.iugu.holder_name,
        number: cc.number.replace(/-/gi, '').replace(/\D/gi, 0),
        bill: {name: ''},
        loaded: true,
      }));

      showAdBlockAlert();

      $loading = false;
    } catch(err) {
      $loading = false;
      if (err.status === 404) {
        $notFound = true;
        return;
      }

      $snackMessage = err.message;
      console.error(err);
    }
  };

  const onSubmitCreditCard = async (values) => {
    try {
      if (showAdBlockAlert()) {
        return;
      }

      const newCreditCards = values.filter(cc => !cc.loaded);

      if (newCreditCards.filter(cc => !cc.valid).length) {
        $loading = false;
        $confirmActionState = true;
        $alertMessage = 'Exclua os cartões com erros para continuar.';
        return $snackMessage;
      }

      $validateCreditCard = true;
      $sentCreditCards = true;

      fetchProposal();
    } catch(err) {
      console.error(err);
      $alertMessage = 'Houve erro ao enviar seu cartão, tente novamente.';
      $loading = false;
    }
  };

  const onValidateCreditCard = async (values, bill) => {
    try {

      const tk = encodeURIComponent($signee.acctk);
      const signeeId = $signee.id;
      const walletId = $IuguCustomerId.walletId;
      const isCreditCardBillPDF = /\.pdf$/.test(bill?.name);

      if (!isCreditCardBillPDF) {
        $loading = false;

        $confirmActionState = true;
        $alertMessage = 'A fatura deve ser uma extensão do tipo .pdf';

        return $snackMessage = 'A fatura deve ser uma extensão do tipo .pdf';
      }

      const [{extra_info, id}, ccCyphered] = await Promise.all([
        iuguService.getIuguToken(values),
        getCypher(
          JSON.stringify(values),
          decodeURIComponent(tk),
          obfuscateNumber(values.number)
        )
      ]);

      const {creditCard: creditCardResult } = await walletService.createCreditCard({
        acctk: tk,
        signeeId,
        walletId,
        iuguToken: id,
        cryptedCreditCard: JSON.stringify(ccCyphered),
        defaultPaymentMethod: values.defaultPaymentMethod,
        description: `${
          extra_info?.brand} - ${
          extra_info?.holder_name} - ${
          extra_info?.display_number}`
      });

      const creditCardId = creditCardResult._id;

      const [result] = await Promise.all([
        walletService.validateCreditCard({
          acctk: tk,
          signeeId,
          walletId,
          creditCardId,
        }),
        walletService.saveFiles({
          signeeId: $signee.id,
          walletId: $IuguCustomerId.walletId,
          creditCardId: creditCardId,
          documents: [bill],
        })
      ]);

      const isValidCard = !!mapLR[result.LR] &&
        mapIuguTransactionMessage[result.message?.toLowerCase()] &&
        mapIuguStatusValidTransaction[result.status];

      $ccValidationResults.push({
        creditCardId,
        iugudata: result,
        message: result.info_message,
        valid: isValidCard,
      });

      if (isValidCard) {
        $confirmSendCard = true;

        await walletService.updateCreditCard({
          acctk: encodeURIComponent($signee.acctk),
          creditCardId,
          iugudata: result,
          signeeId: $signee.id,
          status:'VALID',
          walletId,
        });

        await creditAnalysisService.updateProposalStatus(
          $creditAnalysis.realEstateId,
          $creditAnalysis._id,
          'REVIEW_CARDS',
          tk
        );
      }

      return $ccValidationResults[$ccValidationResults.length - 1];
    } catch(err) {
      console.error(err);

      return {
        valid: false,
        message: 'Falha ao adicionar cartão, tente novamente',
      };
    } finally {
      $loading = false;
    }
  };

  const excludeCreditCard = async (id) => {
    try {
      await walletService.deleteCreditCard({
        acctk: encodeURIComponent($signee.acctk),
        signeeId: $signee.id,
        walletId: $IuguCustomerId.walletId,
        creditCardId: id,
      });

      $snackMessage = 'Removido com sucesso!';
    } catch(err) {
      console.error(err);
      $snackMessage = 'Houve erro ao excluir seu cartão, tente novamente.';
    }
  };

  const beforeUnload = (event) => {
    const message = 'Deseja realmente sair sem enviar os cartões?';
    if ($sentCreditCards)  {
      return message;
    }

    event.preventDefault();
    event.returnValue = message;
    return message;
  };

  fetchProposal();
</script>

<DetailProposal
  onSendCreditCards={onSubmitCreditCard}
  {creditAnalysis}
  {excludeCreditCard}
  {correctData}
  {creditCards}
  {iuguService}
  {loading}
  {notFound}
  {obfuscateNumber}
  {onValidateCreditCard}
  {registerFocus}
  {realEstateName}
  {snackMessage}/>

{#if $firstTutorial && !$correctData}
  <InstructionView dismiss={() => $firstTutorial = false}>
    <h1 slot="title">Seja bem-vindo</h1>
    <div slot="content">
      <p>Chegou a hora de você conferir seus dados e vincular seus cartões de crédito na plataforma FR.</p>
      <p>Se você encontrar algum erro nos dados da sua proposta, entre em contato com o seu corretor.</p>
    </div>
  </InstructionView>
{/if}

{#if $secondTutorial && $correctData}
  <InstructionView dismiss={() => $secondTutorial = false}>
    <h1 slot="title">Dados confirmados!</h1>
    <div slot="content">
      <p>Agora você deve inserir os cartões de crédito que serão utilizados como garantia na operação.</p>
      <p>Nossa plataforma vai validar o cartão com a cobrança de uma transação de autorização, que será estornada posteriormente.</p>
    </div>
  </InstructionView>
{/if}

{#if $validateCreditCard}
  <InstructionView>
    <h1 slot="title">Cartões enviados e validados!</h1>
    <div slot="content">
      <p>Avise o seu corretor e aguarde os próximos passos.</p>
    </div>
  </InstructionView>
{/if}

<ConfirmAction
  open={$confirmActionState}
  onConfirm={() => $confirmActionState = false}
  onCancel={() => $confirmActionState = false}
  type="cancel"
  icon="fact_check"
  confirmLabel="OK"
  cancelLabel="Cancelar">
    {$alertMessage}
</ConfirmAction>

<ConfirmAction
  open={$confirmSendCard}
  onConfirm={() => onSubmitCreditCard($creditCards)}
  onCancel={() => $confirmSendCard = false}
  type="cancel"
  icon="fact_check"
  size="width: 24rem; max-width: calc(100vw - 2rem); height: 15rem;";
  confirmLabel="Finalizar validação dos cartões"
  cancelLabel="Adicionar um novo cartão">
    <p class="text-center">
      Cartão validado com sucesso!<br />
      Deseja validar outro cartão?
    </p>
</ConfirmAction>

<svelte:window on:beforeunload={beforeUnload}/>
