<script>
  import { useLocation, useParams } from 'svelte-navigator';
  import Frame from './components/frame/frame.svelte';

  import { writable } from 'svelte/store';

  const location = useLocation();
  const params = useParams();
  const urlParams = new URLSearchParams($location.search);

  let validContractParams = writable(!!($params.id && urlParams.get('tk')));

  export let title = '';
</script>

{#if $validContractParams}
  <Frame {title}>
    <slot {params} {location} />
  </Frame>
{/if}
