<script>
  import Logo from '../../components/logo/logo.svelte';
</script>

<style>
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #FE4D29;
  } 
</style>

<div class="home">
  <Logo />
</div>
