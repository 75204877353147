<script>
  export let label;
  export let inline = false;
  export let light = false;
  export let space = true;
  export let contentLight = false;
  export let grid;
</script>

<style>
  .inline {
    display: flex;
  }

  .space {
    align-items: center;
    padding-top: 4px;
    margin-left: 5px;
  }

  .light {opacity: 0.6;}

</style>

<div class:inline={inline} class:light={light} class={grid}>
  {#if label}
    <p class="mdc-typography--subtitle1 text-bold">{label}{#if inline}:{/if}</p>
  {/if}
  <p class:space={space} class:light={contentLight} class="mdc-typography--body2"><slot /></p>
</div>
