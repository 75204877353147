<script>
  import Card from '@smui/card';
  import TextIcon from '../text-icon/text-icon.svelte';
  import { Utils} from '../../services/utils';

  export let guarantee;
</script>

<Card padded class="mdc-layout-grid__inner margin-bottom">
  <h3 class="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">Garantia Fiança Rápida</h3>

  <p class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-4-phone type-guarantee text-right">
    {#if guarantee.productName}
      {guarantee.productName}
    {:else}
      {guarantee.name}
    {/if}
  </p>

  <div class="mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    <TextIcon iconName="shield">
      Cobertura de {guarantee.coverage} vezes o valor informado
    </TextIcon>
  </div>

  <p class="text-color-gray mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    Total da Garantia <span style="font-weight:bold; color:#000"> {Utils.formatBRLValue(guarantee.value)}
    </span> Parcelamento:
    {#if guarantee.installmentPlan}
      {guarantee.installmentPlan}
    {:else}
      {guarantee?.payment?.installmentsPlan}
    {/if}
      sem juros
  </p>

  {#if guarantee.setup}
    <p class="text-color-gray mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      Total Setup <span style="font-weight:bold; color:#000"
      > {Utils.formatBRLValue(guarantee.setup)}</span
      > Parcelamento Setup: {guarantee.installmentSetup} sem juros
    </p>
  {/if}

  <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
    <div class="rent-detail-value">
      <TextIcon iconName="paid">Valor Informado: <br /></TextIcon>
      <span class="text-bold position-right">
        {Utils.formatBRLValue(Object
          .keys(guarantee.rent)
          .reduce((acc, key) => acc + guarantee.rent[key], 0))}
      </span>
    </div>
  </div>
</Card>

<style>
  :global(.guarantee-title-wrapper) {
    display: flex;
    justify-content: space-between;
  }

  :global(.text-color-gray) {
    color: #00000099;
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
  }

  :global(.title-card-guarantee) {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  :global(.type-guarantee) {
    font-weight: bold;
    color: #1565c0;
  }
</style>
