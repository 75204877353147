<script>
  import './App.scss';

  import { Router, Route } from 'svelte-navigator';

  import ProtectRoute from './protect-routes.svelte';

  import Home from './views/home/home.svelte';

  import DetailCreditAnalysis from './views/credit-analysis/detail/detail.controller.svelte';
  import DetailProposal from './views/proposal/detail/detail.controller.svelte';

  import GtagEvents from './components/gtag-events/gtag.svelte';

  import { desktopMode, tabletMode } from './store/stores';

  const desktopMedia = '(min-width: 1200px)';
  const tabletMedia = '(min-width: 768px) and (max-width: 1024px)';
  const desktopMatch = matchMedia(desktopMedia).matches;
  const tabletMatch = matchMedia(tabletMedia).matches;

  $desktopMode = desktopMatch;
  $tabletMode = tabletMatch;
</script>

<Router>
  <Route path="/">
    <GtagEvents type="event" eventName="screen_view" immediate={true} value={{
      'screen_name': 'Home'
    }}>
      <Home />
    </GtagEvents>
  </Route>

  <Route path="credit-analysis/:id/*" primary>
    <ProtectRoute path="cvv" let:location >
      <GtagEvents type="event" eventName="screen_view" immediate={true} value={{
        'screen_name': 'Dados da Proposta'
      }}>
        <DetailCreditAnalysis />
      </GtagEvents>
    </ProtectRoute>
  </Route>

  <Route path="proposal/:id/ccv" >
    <ProtectRoute>
      <GtagEvents type="event" eventName="screen_view" immediate={true} value={{
        'screen_name': 'Dados da Proposta'
      }}>
        <DetailProposal/>
      </GtagEvents>
    </ProtectRoute>
  </Route>
</Router>
