<script>
  export let type;
  export let eventName;
  export let value;
  export let immediate;

  const track = (cb) => {
    // eslint-disable-next-line no-undef
    gtag(type, eventName, value)

    return cb;
  };

  immediate && track();
</script>

<slot {track}/>
