// eslint-disable-next-line no-undef
const isProduction = () => (process.env.NODE_ENV === 'production');
// eslint-disable-next-line no-undef
const IUGU_ACCOUNT_ID = `${process.env.IUGU_ACCOUNT_ID}`;

// eslint-disable-next-line no-undef
const iugu = Iugu;

export const IuguService = () => {

  iugu.setAccountID(IUGU_ACCOUNT_ID);
  iugu.setTestMode(!isProduction());

  return ({
    validateCreditCard(number) {
      return iugu.utils.validateCreditCardNumber(number.replace(/\D/g, ''));
    },
    getBrandByCreditCardNumber(number) {
      return iugu.utils.getBrandByCreditCardNumber(number);
    },
    validateCVV(cvv, brand) {
      return iugu.utils.validateCVV(cvv, brand);
    },
    validatedCreditCardNumberAndBrand(number) {
      if (number.length < 12) {
        return {
          isValid: true,
          brand: ''
        };
      }

      const isValid = iugu.utils.validateCreditCardNumber(number.replace(/\D/g, ''));
      const brand = iugu.utils.getBrandByCreditCardNumber(number.replace(/\D/g, ''));
      return {
        isValid,
        brand
      };
    },
    validateExpiration(exp) {
      return iugu.utils.validateExpirationString(exp);
    },
    getIuguToken(cc) {
      const {
        number,
        month,
        year,
        cvv: verification_value,
        name: full_name,
      } = cc;


      const first_name = full_name.split(' ')[0];
      const last_name = full_name.split(' ').pop();

      const creditCard = iugu.CreditCard(number, month, year, first_name, last_name, verification_value);

      return new Promise(
        (resolve, reject) =>
          iugu.createPaymentToken(creditCard.toData(), (data, errors) =>
            errors ? reject(errors) : resolve(data)
        )
      );
    },
    isBlockedByAdBlock() {
      return iugu.utils.isBlockedByAdBlock();
    }
  });
};
