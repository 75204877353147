<script>
  import { Icon } from '@smui/icon-button';

  import amex from '../../../src/images/amex.svg';
  import elo from '../../../src/images/elo.svg';
  import hipercard from '../../../src/images/hipercard.svg';
  import mastercard from '../../../src/images/mastercard.svg';
  import visa from '../../../src/images/visa.svg';

  export let brand;
  export let width = '100%';

  const images = {
    amex,
    elo,
    hipercard,
    mastercard,
    visa
  };

</script>

{#if images[brand]}
  <div class="logo-wrapper">
    <img class="logo-credit-card" alt={brand} src={images[brand]} style={`width: ${width}`}/>
  </div>
{:else}
  <Icon class="material-icons cc-card-icon">credit_card</Icon>
{/if}

<style>
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-credit-card {
    margin-top: -.5rem;
    max-width: 5.6rem;
    max-height: 4rem;
  }

  :global(.cc-card-icon) {
    font-size: 2.8rem;
    font-weight: 100;
  }

  @media (max-width: 30rem) {
    .logo-credit-card {
      margin-top: 1rem;
      max-width: 6rem;
      max-height: 4rem;
    }
  }

</style>
