<script>
  import logo from '../../../src/images/logo.svg';
</script>

<style>
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-fianca-rapida {
    width: 5rem;
  }

  .name-fianca-rapida {
    font-family: Lato;
    font-weight: 600;
    font-size: 3rem;
    color: #fff;
  }
</style>

<div class="logo-wrapper">
  <span class="name-fianca-rapida">Fiança Rápida</span> 
  <img class="logo-fianca-rapida" alt="Logo Fiança Rápida" src={logo}/>
</div>
