<script>
  import { Icon } from '@smui/button';

  export let iconName;
  export let lockSize = '';
</script>

<div class="text-icon" class:max-width={lockSize}>
  <Icon class="material-icons icon">{iconName}</Icon>
  <p class="text">
    <slot/>
  </p>
</div>

<style>
  .text-icon {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
  }

  :global(.text) {
    margin-left: .5rem;
    color: #00000099;
    overflow-wrap: break-word;
  }

  :global(.max-width) {
    max-width: 16rem;
  }

  :global(.icon) {
    color: #707070;
  }
</style>
