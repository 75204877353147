// eslint-disable-next-line no-undef
const host = `${process.env.API}`;
const headers = new Headers();

headers.append('Content-Type', 'application/json');

export const WalletService = () => ({
  createCustomer: ({
    signeeId,
    acctk,
    email,
    proposalId,
  }) => fetch(`${host}/v1/signee/${signeeId}/wallet`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      acctk,
      proposalId,
      email,
    }),
  }).then(res => res.json()),
  createCreditCard: ({
    signeeId,
    walletId,
    acctk,
    iuguToken,
    description,
    cryptedCreditCard,
    defaultPaymentMethod,
  }) => fetch(`${host}/v1/signee/${signeeId}/wallet/${walletId}/creditcard`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      acctk,
      iuguToken,
      description,
      cryptedCreditCard,
      defaultPaymentMethod,
    }),
  }).then(res => res.json()),
  getCreditCards: ({
    signeeId,
    walletId,
    acctk,
  }) => fetch(`${host}/v1/signee/${signeeId}/wallet/${walletId}/creditcards?acctk=${acctk}`, {
    headers: {
      ...headers,
      acctk: acctk,
    }
  }).then(res => res.json()),
  validateCreditCard: ({
    signeeId,
    walletId,
    creditCardId,
    acctk,
  }) => fetch(`${host}/v1/signee/${
      signeeId}/wallet/${
      walletId}/creditcard/${
      creditCardId}/valid?acctk=${acctk}`, {
        headers: {
          ...headers,
          acctk: acctk,
        }
      })
    .then(res => res.json()),
  deleteCreditCard: ({
    signeeId,
    walletId,
    creditCardId,
    acctk,
  }) => fetch(`${host}/v1/signee/${
    signeeId}/wallet/${
    walletId}/creditcard/${
    creditCardId}?acctk=${acctk}`, {
      method: 'DELETE',
      headers: {
        ...headers,
        acctk: acctk,
      }
    }).then(res => {
      if (res.status !== 200) {
        throw new Error('Error deleting credit card');
      }

      res.json()
    }),
  updateCreditCard: ({
    signeeId,
    walletId,
    creditCardId,
    acctk,
    status,
    iugudata,
  }) => fetch(`${host}/v1/signee/${signeeId}/wallet/${
    walletId}/creditcard/${creditCardId}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        acctk,
        iugudata,
        status,
      }),
    }).then(res => res.json()),
  saveFiles: ({
    signeeId,
    walletId,
    creditCardId,
    documents,
  }) =>
    fetch(`${host}/v1/signee/${signeeId}/wallet/${walletId}/creditcard/${creditCardId}/documents`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        type: 'CREDIT_CARD_BILL',
        documents,
      }),
    }).then(res => {
      if (res.status !== 200) {
        throw new Error('Erro ao salvar arquivos');
      }
    }),
});
