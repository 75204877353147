<script>
  import TopAppBar, { Row, Section, Title } from '@smui/top-app-bar';
</script>

<style>
  :global(.brand-font) {
    font-family: 'Lato', sans-serif !important;
    font-size: 1.75rem;
    font-weight: 600;
  }
</style>

<TopAppBar variant="fixed" color="primary">

  <Row>
    <Section>
      <Title class="brand-font">Fiança Rápida</Title>
    </Section>
  </Row>

</TopAppBar>

