<script>
  import Card from '@smui/card';
  import { Icon } from '@smui/icon-button';
  import TextIcon from '../text-icon/text-icon.svelte';

  import {tabletMode} from '../../store/stores';

  import { Utils } from '../../services/utils';

  export let realtyData;

  const rentTypeMap = {
    res: 'Residencial',
    com: 'Comercial',
  }
</script>

<Card padded class="mdc-layout-grid__inner margin-bottom">

  <h3 class="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">Dados da locação</h3>

  <div class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone rent-text-icon">
    <Icon class="material-icons">home</Icon>
    <p class="rent-type-text text-light" class:text-right={!$tabletMode}>
      {rentTypeMap[realtyData.type]}
    </p>
  </div>

  {#if !realtyData.address && realtyData.streetName}
    <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <TextIcon iconName="location_on">
        {realtyData.streetName}
        {realtyData.number}, {realtyData.addressComplement}
        <br />
        {realtyData.neighborhood}
        {realtyData.city} - {realtyData.state}
        {realtyData.zipcode}
      </TextIcon>
    </div>
  {:else}
    <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <TextIcon iconName="location_on">
        {realtyData.address.streetName}
        {realtyData.address.number}, {realtyData.address.addressComplement}
        <br />
        {realtyData.address.neighborhood}
        {realtyData.address.city} - {realtyData.address.state}
        {realtyData.address.zipcode}
      </TextIcon>
    </div>

    <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
      <div class="rent-detail-value">
        <TextIcon iconName="paid">Valor Informado: <br /></TextIcon>
        <span class="text-bold position-right">
          {Utils.formatBRLValue(Object
            .keys(realtyData.rent)
            .reduce((acc, key) => acc + realtyData.rent[key], 0))}
        </span>
      </div>
    </div>
  {/if}
</Card>

<style>
  :global(.rent-text-icon) {
    display: flex;
    justify-content: right;
  }

  :global(.rent-detail-value) {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  :global(.rent-type-text) {
    font-weight: bold;
    color: #1565c0;
  }

  :global(.rent-type-icon) {
    color: #00000099;
    margin-right: 0.5rem;
  }
</style>
