<script>
  import IconButton, { Icon } from '@smui/icon-button';
  import Button, { Label } from '@smui/button';

  export let labelButton;
  export let getInfoUploadFile;
  export let resetFile = false;

  let base64;
  let file = {};

  const attachFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';

    input.addEventListener('change', () => {
      file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (base64 = e.target.result.split(',')[1]);
      reader.readAsDataURL(file);
    });
    input.click();
  };

  const removeFile = () => {
    file = '';
    base64 = '';
    getInfoUploadFile(file, base64);
  };

  $: if (file.name && base64) {
    getInfoUploadFile(file, base64);
  }

  $: if ($resetFile) {
    $resetFile = false;
    removeFile();
  }
</script>

{#if file.name}
  <div style="display: inline-flex; width: 6rem; max-width: 6rem;">
    <p class="text-light text-overflow-ellipsis">{file.name}</p>
  </div>
  <IconButton on:click={removeFile} class="material-icons cursor-pointer" title="delete">delete</IconButton>
{:else}
  <Button color='primary' type='button' on:click={attachFile}>
    <Icon class='material-icons'>attach_file</Icon>
    <Label>{labelButton}</Label>
  </Button>
{/if}
