<script>
  import './summary-card.scss';
  import Card from '@smui/card';

  import { Utils } from '../../services/utils';
  import LabeledText from '../labeled-text/labeled-text.svelte';

  export let contract = {};
  export let realEstateName = {};
</script>

<Card padded class="mdc-layout-grid__inner margin-bottom">

  <div class="mdc-layout-grid__cell--span-8">
    <h3 class="mdc-typography--headline5 ">Detalhes da garantia</h3>
  </div>

  <div class="mdc-layout-grid__cell--span-4">
    <div class="text-light text-bold text-right">
      {$contract?.frId}
    </div>
  </div>

  <LabeledText label="Imobiliária Responsável" space={false} contentLight={true} grid="mdc-layout-grid__cell--span-12">
    {$realEstateName[$contract.realEstateId]}
  </LabeledText>

  {#each $contract.signees as signee}
    <div class="mdc-layout-grid__cell--span-12 row-margin-bottom">
      <p class="mdc-layout-grid__cell--span-12 row-summary">{signee.renter ? 'Inquilino contratante' : 'Corresponsável'}</p>
      <p class="mdc-layout-grid__cell--span-6 text-bold">{signee.name}</p>
      <p class="mdc-layout-grid__cell--span-6 signee-email row-summary text-light">{signee.email}</p>
      <p class="mdc-layout-grid__cell--span-12 text-light">{Utils.applyMask('(00) 00000-0000')(signee.phone.replace(/\D/gi, ''))}</p>
    </div>
  {/each}

  <div class="mdc-layout-grid__inner">
    <slot></slot>
  </div>
</Card>
